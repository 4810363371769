body {
  margin: 0;
}

html,
body,
div#root,
main {
  height: 100vh;
  overflow: hidden;
}

/*
 * Hyouk Seo 'customize-scrollbar'
 * https://codepen.io/Spemer/pen/baoKPK
 */
/* total width */
*::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: #fff;
}

*::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/*@media (prefers-color-scheme: dark) {
  *::-webkit-scrollbar {
    background-color: #000;
  }

  *::-webkit-scrollbar-track {
    background-color: #000;
  }

  *::-webkit-scrollbar-track:hover {
    background-color: #000;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #444;
    border-radius: 16px;
    border: 5px solid #000;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    border: 4px solid #000;
  }
}*/

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}

body,
button {
  font-family: Poppins, Arial, Helvetica, sans-serif;
}

.text-danger {
  color: #bc162b;
}

.text-success {
  color: #28a745;
}

.text-warning {
  color: #ed6c02;
}

.badge-pill {
  padding-right: 0.3em;
  padding-left: 0.2em;
}
